import React from "react";
import jwt from 'jwt-decode';
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  Row,
  Col
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import Pagination from '../../components/commons/Pagination';
import {getUserSpenditure, getUserSpenditureV1} from '../../api/ApiUtils';
import CardFooter from "reactstrap/lib/CardFooter";

var dateFormat = require('dateformat');
// import ErrorDisplay from '../../components/commons/ErrorDisplay';

class ViewUserSpenditure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          name: '',
          amount: '',
          currency: '',
          date: '',
          spenditure_id: '',
          mySpenditure: [],
          curentMonthSpenditure: '',
          chart_data: '',
          pageNo: 0,
          limit: 20
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        
      }

 async componentDidMount() {
    var accesstoken = localStorage["accesstoken"];
    var decoded = jwt(accesstoken);
    var pageNo = this.state.pageNo
    var limit = this.state.Limit

    const response = await getUserSpenditureV1(decoded.userId, pageNo, limit);
    console.log(response)
    this.setState({
      // curentMonthSpenditure: response.data.data.thisMonth,
      mySpenditure: response.data.data.spenditures.user_spenditures,
      // chart_data: response.data.data.chart_data
    });
   
  }
  render() {
    console.log(this.state.chart_data.labels);
    const labels_array = this.state.chart_data.labels;
    console.log();

    const data = {
      datasets: [
        {
          label: "Spenditures",
          data:  this.state.chart_data.values,
          maxBarThickness: 10,
          backgroundColor: this.state.chart_data.colors,
          hoverBorderWidth: 12,
          labels: [labels_array]
        }
      ],
      labels: labels_array,
    }

    const options =  {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function(value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ]
      },
  
      tooltips: {
        callbacks: {
          label: function(item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      }
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">My Expenses</h3>
    {/* <h3 className=" mb-0">Current Month: {this.state.curentMonthSpenditure}</h3> */}
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Index</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Currency</th>
                      {/* <th scope="col">Completion</th> */}
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.mySpenditure.map((spenditure, index) => (
                        <tr>
                        <td>{index}</td>
                        <td>{spenditure.spenditure ? spenditure.spenditure.name : "null"}</td>
                        <td>{dateFormat(spenditure.date, " dS mmm")}</td>
                        <td>{spenditure.amount}</td>
                        <td>{spenditure.currency}</td>
                        </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          {/* <Row>
          <Col className="mt-5 mb-5">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                
                  <div className="chart">
                    <Doughnut
                      data={data}
                      options={options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
           <CardFooter>
                  <Pagination />
                </CardFooter>
        </Container>
      </>
    );
  }
}


export default ViewUserSpenditure;