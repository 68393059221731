import Index from "./views/Index.js";
import Profile from "./views/examples/Profile.js";
import Maps from "./views/examples/Maps.js";
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import Tables from "./views/examples/Tables.js";
import Icons from "./views/examples/Icons.js";
import AddSpediture from "./views/examples/AddSpenditureItem";
import AddSpeditureCategory from "./views/examples/Spenditure/AddSpenditureCategory";
import AddUserSpediture from "./views/examples/AddUserSpenditure";
import ViewUserSpenditure from  "./views/examples/ViewUserSpenditure";
import AddBudget from "./views/examples/Budget/Addbudget";
import ViewUserBudget from "./views/examples/Budget/ViewUserBudget";
import SingleBudgetView from './views/examples/Budget/SingleBudgetView';
import Analytics from "./views/examples/analytics";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    show: true
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-tv-2 text-primary",
    component: Analytics,
    show: true,
    layout: "/admin"
  },
  {
    path: "/add-spenditure-category",
    name: "Add Expense Category",
    icon: "ni ni-basket text-green",
    component: AddSpeditureCategory,
    show: true,
    layout: "/admin"
  },
  {
    path: "/add-spenditure",
    name: "Add Expense",
    icon: "ni ni-basket text-green",
    component: AddSpediture,
    show: true,
    layout: "/admin"
  },
  {
    path: "/add-user-spenditure",
    name: "Add User Expense",
    icon: "ni ni-basket text-green",
    component: AddUserSpediture,
    show: true,
    layout: "/admin"
  },
  {
    path: "/my-spenditure",
    name: "My Expense",
    icon: "ni ni-basket text-green",
    component: ViewUserSpenditure,
    show: true,
    layout: "/admin"
  },
  {
    path: "/add-budget",
    name: "Add Budget",
    icon: "ni ni-basket text-green",
    component: AddBudget,
    show: true,
    layout: "/admin"
  },
  {
    path: "/view-budget",
    name: "View Budget",
    icon: "ni ni-basket text-green",
    component: ViewUserBudget,
    show: true,
    layout: "/admin"
  },
  {
    path: "/view-single-budget",
    name: "Single Budget View",
    icon: "ni ni-basket text-green",
    component: SingleBudgetView,
    layout: "/admin",
    show: false
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    show: true,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    show: true,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    show: true,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    show: true,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  }
];
export default routes;
