import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Table,
  Row
} from "reactstrap";
// core components
import Header from "../../../components/Headers/Header.js";
import {getSingleBudget} from '../../../api/ApiUtils';
import Pagination from '../../../components/commons/Pagination';
import CardFooter from "reactstrap/lib/CardFooter";

var dateFormat = require('dateformat');
// import ErrorDisplay from '../../components/commons/ErrorDisplay';

class SingleBudgetView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {     
          budget_items: [],
          budget: ''
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        
      }

 async componentDidMount() {
    const budget = this.props.location.state;
    const response = await getSingleBudget(budget.year, budget.month);
    this.setState({
      budget: response.data.data.budget,
      budget_items: response.data.data.budget.budget_items
    });
   
  }
  render() {
    const {budget, budget_items} = this.state;
    console.log(budget_items);
    var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">My Budget of {`${months[budget.month-1]} / ${budget.year}`}</h3>
                  <h3 className=" mb-0">Total Budget Amount {`KSH ${budget.amount}`}</h3>
                  <h3 className=" mb-0">Actual Amount Spent {`KSH ${budget.actual_amount_spent}`}</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Index</th>
                      <th scope="col">Item</th>
                      <th scope="col">Amount Allocated</th>
                      <th scope="col">Amount Spent</th>
                      {/* <th scope="col" /> */}
                    </tr>
                  </thead>
                  <tbody>
                    {budget_items.map((budget, index) => (
                        <tr>
                        <td>{index+1}</td>
                        <td>{budget.spenditure_category.name}</td>
                        <td>{budget.amount}</td>
                        <td>{budget.amount_spent}</td>
                        {/* <td>{budget.currency}</td> */}
                        </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter>
                  <Pagination />
                </CardFooter>
             
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


export default SingleBudgetView;