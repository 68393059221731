import { API_BASE_URL, accesstoken } from "./baseUrl";
const axios = require('axios');

console.log(process.env.BASE_URL)
console.log(process.env.BASE_URL)
console.log(process.env.BASE_URL)   

export const http = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': localStorage.getItem('accesstoken')
    },
});

const request = (options) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

    // console.log(localStorage.getItem(accesstoken));

    if (localStorage.getItem(accesstoken)) {
        // console.log(localStorage.getItem(accesstoken));
        // axios.defaults.headers.common["Authorization"] = accesstoken;
        headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': localStorage.getItem(accesstoken)
        }
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    // console.log(options.headers);
    // var postmethod = options.method;
    if (options.method === "POST") {

        return axios.post(options.url, options.body, { headers: headers }).then(function(response) {
            //  console.log(response);
            return response;
        }).catch(function(err) {
            console.log(err);
           if(err.request && err.request.status === 403) {
            let appState = {
                isLoggedIn: false,
                user: {}
              };
              localStorage.removeItem("accesstoken");
              localStorage.removeItem("copid");
              localStorage["appState"] = JSON.stringify(appState);
            //   this.props.history.push('/login');
            window.location.reload();
        }
            // console.log(err.message)
            // console.log(err.request)
            const error = {
                error: err
            }

            return error;
        });
    }

    if (options.method === "GET") {

        return axios({
            method: 'get',
            url: options.url,
            data: options.body,
            headers: headers
        })
    }

};

//User Login API
export function userLogin(data) {
    //  console.log(loginrequest);
    return request({
        url: API_BASE_URL + "/user/login",
        method: 'POST',
        body: data
    });
}

//User register API
export function userRegister(data) {
    return request({
        url: API_BASE_URL + "/user/register",
        method: 'POST',
        body: data
    });
}

//reset user password request
export function forgotPassword(data) {
    return request({
        url: API_BASE_URL + "/users/forgotpassword",
        method: 'POST',
        body: data
    });
}

//update user password request
export function updatePassword(data, token) {
    return request({
        url: API_BASE_URL + "/users/updatepassword/"+token,
        method: 'POST',
        body: data
    });
}

//register a spenditure item
export function addSpenditureItem(data) {
    return request({
        url: API_BASE_URL + "/spenditure",
        method: 'POST',
        body: data
    })
}

//return all the spenditure items from the api
export function getSpenditureItems() {
    return request({
        url: API_BASE_URL + "/spenditure",
        method: 'GET',
        body: null 
    });
}

//register user spenditure item
export function addUserSpenditure(data) {
    return request({
        url: API_BASE_URL + "/api/v1/users/spenditure",
        method: 'POST',
        body: data
    })
}

//get the spenditure of a specific user
export function getUserSpenditure(userID) {
    return request({
        url: API_BASE_URL + "/user/"+userID+"/spenditure",
        method: 'GET',
        body: null 
    });
}

export function getUserSpenditureV1(userID, pageNo, limit) {
    return request({
        url: API_BASE_URL + "/api/v1/users/"+userID+"/spenditure?pageNo="+pageNo+"&limit="+limit,
        method: 'GET',
        body: null 
    });
}

//register user budget item
export function addUserBudget(data) {
    return request({
        url: API_BASE_URL + "/budget",
        method: 'POST',
        body: data
    })
}

//return all the budgets items from the api
export function getBudgets() {
    return request({
        url: API_BASE_URL + "/budget",
        method: 'GET',
        body: null 
    });
}

export function getSingleBudget(year, month) {
    return request({
        url: API_BASE_URL + "/budget/?year="+year+"&month="+month,
        method: 'GET',
        body: null 
    });
}

//register a spenditure category
export function addExpenseCategory(data) {
    return request({
        url: API_BASE_URL + "/api/v1/spenditure_categories",
        method: 'POST',
        body: data
    })
}

//return user spenditure categories
export function getUserExpenseCategories(data) {
    return request({
        url: API_BASE_URL + "/api/v1/spenditure_categories",
        method: 'GET',
        body: data
    })
}





//Organization register

//Get organization
export function getUserOrg() {
    return request({
        url: API_BASE_URL + "/coporates/get/admin",
        method: 'GET',
        body: null 
    });
}

//register an organiation role user
export function registerOrganizationRoleUser(data) {
    return request({
        url: API_BASE_URL + "/roles/assign",
        method: 'POST',
        body: data
    })
}

//get organizations roles users and role settings
export function getOrganizationRoles(id) {
    return request({
        url: API_BASE_URL + "/roles/"+id,
        method: 'GET',
        body: null 
    });
}


