const dotenv = require('dotenv');
dotenv.config();

// export const API_BASE_URL = 'http://localhost:3500'
export const accesstoken = 'accesstoken';
export const API_BASE_URL = 'https://dev.elidayjuma.com/chisendi';


// https://chisendi-api.herokuapp.com/user/login

//  rte = scp -r build eliday@165.22.16.82:/var/www/html/chisendi/chisendi_web/