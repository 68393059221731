import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import {addSpenditureItem, getUserExpenseCategories} from '../../api/ApiUtils';
import ErrorDisplay from '../../components/commons/ErrorDisplay';

class Icons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          name: '',
          spenditure_categories: [],
          spenditure_category_id: ''
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        console.log("submmiting login")
        // console.log(`this is user data ${JSON.stringify(this.state.user)}`)
        this.setState({
          errormessage: '',
          show: false,
          formSubmitting: true
         });
      
        let data ={
          name: this.state.name,
          spenditure_category_id: this.state.spenditure_category_id
        }
      
        const response = await addSpenditureItem(data);
      
        if(response.data) {
          this.setState({
            isRegistered: true,
            show:true,
            formSubmitting: false,
            name:''
          });
          // this.props.history.push('/dashboard');
        //   window.location.reload()
        }
      
        if (response.error) {
          console.log(response.error);
          // console.log(response.error.response);
          if (response.error.response != null) {
            this.setState({
              errormessage: response.error.response.data.data.message,
              show: true,
              formSubmitting: false
            });
          } else {
            this.setState({
              errormessage: response.error.response,
              show: true,
              formSubmitting: false
            });
          }
        }
      }
//handle input change
handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    // console.log(target.value)
    this.setState({
        [inputName]: inputValue
    });
  }

  async componentDidMount() {
   
    const response = await getUserExpenseCategories();
    if(response.data) {
       var spenditure_categories =  response.data.data.spenditure_categories;
      this.setState({
        spenditure_categories
      });
    }
// console.log(this.state.spenditures)
 
}

  render() {
    const {spenditure_categories} = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Spenditure Item</h3>
                </CardHeader>
                <CardBody>
                  <ErrorDisplay 
                    show={this.state.show}
                    errormessage={this.state.errormessage}
                    formSubmitting={this.state.formSubmitting} 
                    isRegistered={this.state.isRegistered}/>
                  <Row className=" icon-examples">
                  <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-basket" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" required name="name" value={this.state.name} type="text" autoComplete="new_name" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-basket" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <div>
                                <Input type="select" name="spenditure_category_id" required id="exampleSelect" onChange={this.handleInputChange}>
                                  <option>Expense Item</option>
                                  {spenditure_categories.map(spenditure => (

                                    <option value={spenditure.id} >{spenditure.name}</option>

                                  ))}
                                </Input>
                              </div>
                        </InputGroup>
                      </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Add Item
                  </Button>
                </div>
              </Form> 
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Icons;
