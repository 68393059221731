import React from "react";
import {withRouter, Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {loginAction} from '../../actions'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import {userLogin} from '../../api/ApiUtils';
import ErrorDisplay from '../../components/commons/ErrorDisplay';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistered: false,
      error: '',
      errorMessage: '',
      formSubmitting: false,
      email: '',
      password: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    console.log("submmiting login")
    // console.log(`this is user data ${JSON.stringify(this.state.user)}`)
    this.setState({
      errormessage: '',
      show: false,
      formSubmitting: true
     });
  
    let userData ={
      email: this.state.email,
      password: this.state.password
    }

    const {reducerLogin} = this.props;
    reducerLogin(userData)
  
    const response = await userLogin(userData);
  
    if(response.data) {
      let appState = {
        isLoggedIn: true,
        formSubmitting: false
      };
      let accesstoken = response.data.data.token;
      
      localStorage["appState"] = JSON.stringify(appState);
      localStorage['accesstoken'] = accesstoken;
      this.setState({
        isLoggedIn: appState.isLoggedIn,
        user: appState.user,
        error: ''
      });
      // this.props.history.push('/dashboard');
      window.location.reload()
      // return this.props.history.push('/dashboard');
    }
  
    if (response.error) {
      console.log(response.error);
      // console.log(response.error.response);
      if (response.error.response != null) {
        this.setState({
          errormessage: response.error.response.data.data.message,
          show: true,
          formSubmitting: false
        });
      } else {
        this.setState({
          errormessage: response.error.response,
          show: true,
          formSubmitting: false
        });
      }
    }
  }
   //handle input change
 handleInputChange = (event) => {
  const target = event.target;
  const inputName = target.name;
  const inputValue = target.value;
  
  this.setState({
      [inputName]: inputValue
  });
}

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../../assets/img/icons/common/fb.png")}
                    />
                  </span>
                  <span className="btn-inner--text">facebook</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../../assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <ErrorDisplay 
                show={this.state.show}
                errormessage={this.state.errormessage}
                formSubmitting={this.state.formSubmitting} 
                isRegistered={this.state.isRegistered}/>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" name="email" type="email" autoComplete="new-email" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" name="password" type="password" autoComplete="new-password" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <Link 
                className="text-light"
                to="/auth/register">
                  <small>Create new account</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const actionCreators = {
  reducerLogin: loginAction
};

// creating a dispatcher that will fire my action
const mapDispatchToProps = dispatch => {
  return bindActionCreators(actionCreators, dispatch);
};

// accessing redux store
const mapStateToProps = state => {
  return {
    login: state.login
  }
}

// connecting login component with redux store and mapping values to props for accessibility
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
