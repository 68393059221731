import humps from 'humps'

export const loginReducer = (state = {}, action) => {

  const {payload} = action;

  switch (action.type) {

    // case that is fired when the request has been made via api
    case 'LOGIN_USER_REQUEST':
      return Object.assign({}, state, {
        loading: true,
      });

    // case that handles success response has been made
    case 'LOGIN_USER_SUCCESS':
      const {status, data: {findUser}} = humps.camelizeKeys(payload)
      return Object.assign({}, state, {
        requestStatus: status,
        ...findUser,
        loading: false,
      });

    // handles failed response from api call
    case 'LOGIN_USER_FAILED':
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
};