import React from "react";
import { 
  Col, 
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row } from "reactstrap";
import RenderBudgetItems from "./RenderBudgetItems";

const ExpenseItemForm = ({ props, spenditure_categories, budget_items, handleBudgetItemArray,handleInputChange }) => {
  return (
    <div>
      <RenderBudgetItems budget_items={budget_items} />

      <Form role="form" className="ml-4 mt-4" onSubmit={handleBudgetItemArray}>
        <Row>
          {/* <Col lg="4">
            <FormGroup>
              <Input
                className="form-control-alternative"
                id="input-item"
                name="temp_budget_item"
                placeholder="salt"
                type="text"
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </Col> */}
          <Col lg="4">
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <div>
                  <Input type="select" name="temp_budget_item" required id="exampleSelect" onChange={handleInputChange}>
                    <option>Expense Item</option>
                    {spenditure_categories.map(spenditure => (

                      <option value={spenditure.id+"-"+spenditure.name} >{spenditure.name}</option>

                    ))}
                  </Input>
                </div>
              </InputGroup>
            </FormGroup>
          </Col>

          <Col lg="4">
            <FormGroup>
              <Input
                className="form-control-alternative"
                id="input-amount"
                name="temp_budget_amount"
                placeholder="200"
                type="text"
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <Button className="my-0" color="primary" type="submit">
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ExpenseItemForm;
