import {combineReducers} from 'redux';
import {loginReducer} from "./login/login-reducer";


// combines all the reducer function for code splitting purposes & scalability
const rootReducer = combineReducers({
  login: loginReducer

});

export default rootReducer;
