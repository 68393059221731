import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row
} from "reactstrap";
// core components
import Header from "../../../components/Headers/Header.js";
import {addExpenseCategory} from '../../../api/ApiUtils';
import ErrorDisplay from '../../../components/commons/ErrorDisplay';

class SpenditureCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          name: ''
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(`this is user data ${JSON.stringify(this.state.user)}`)
        this.setState({
          errormessage: '',
          show: false,
          formSubmitting: true
         });
      
        let data ={
          name: this.state.name
        }
      
        const response = await addExpenseCategory(data);
      
        if(response.data) {
          this.setState({
            isRegistered: true,
            show:true,
            formSubmitting: false,
            name:''
          });
          // this.props.history.push('/dashboard');
        //   window.location.reload()
        }
      
        if (response.error) {
          console.log(response.error);
          // console.log(response.error.response);
          if (response.error.response != null) {
            this.setState({
              errormessage: response.error.response.data.data.message,
              show: true,
              formSubmitting: false
            });
          } else {
            this.setState({
              errormessage: response.error.response,
              show: true,
              formSubmitting: false
            });
          }
        }
      }
//handle input change
handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    // console.log(target.value)
    this.setState({
        [inputName]: inputValue
    });
  }
  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Expense Category</h3>
                </CardHeader>
                <CardBody>
                  <ErrorDisplay 
                    show={this.state.show}
                    errormessage={this.state.errormessage}
                    formSubmitting={this.state.formSubmitting} 
                    isRegistered={this.state.isRegistered}/>
                  <Row className=" icon-examples">
                  <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-basket" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" required name="name" value={this.state.name} type="text" autoComplete="new_name" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Add Category
                  </Button>
                </div>
              </Form> 
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SpenditureCategory;
