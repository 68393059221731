import React, { Component } from "react";
import { Alert } from "shards-react";
import PulseLoader from "react-spinners/PulseLoader";
 
 
class ErrorDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorState: this.props.errorState,
            errormessagearray: this.props.errormessage,
            errorMessage: '',
            show: this.props.show
        }
    }
 
 
 
    render() {
        // console.log(this.state.errormessagearray)
        // console.log(this.props.show)
        // console.log(this.props)
        let errorMessage = this.props.errormessage;
        let arr = [];
        if (typeof (errorMessage) == Array) {
            Object.values(errorMessage).forEach((value) => (
                arr.push(value)
            ));
        } else {
            arr.push(errorMessage);
        }
        return (
           <div>
                {this.props.formSubmitting ? <PulseLoader size={15} color={"#123abc"} loading={this.props.formSubmitting} /> : ""}
                {this.props.isRegistered ? <Alert open={this.props.show} theme="success">Request was successful...</Alert> :""}
                {this.props.errormessage ? 
                    <ul style={{ listStyleType: 'none' }}>
                        {arr.map((item, i) => (        
                          <li key={i}>
                              <p style={{ color: 'white' }}>
                                <Alert open={this.props.show} theme="danger">{item}<br/></Alert>
                              </p>
                        </li>
                        ))}
                    </ul>
                : ""}
           </div>
 
        );
    }
}
 
export default ErrorDisplay;
 
 
