import {http} from '../../api/ApiUtils';

/**
 *  ============= NOTE ===========
 *  middleware will intercept the request because it meets the standards
 *  3 types action types and callAPI function
 *   =============================
 * @param data
 * @returns {{types: [string, string, string], payload: {}, callAPI: (function(): Promise<* | {error: *}> | AxiosPromise<any> | undefined)}}
 */
export const loginAction = (data) => {
  return {
    types: [
      'LOGIN_USER_REQUEST',
      'LOGIN_USER_SUCCESS',
      'LOGIN_USER_FAILURE'
    ],
    payload:{},
    callAPI: () => http.post("/users/login", data)
  };
};
