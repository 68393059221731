import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Row
} from "reactstrap";
// core components
import Header from "../../../components/Headers/Header.js";
import {getBudgets} from '../../../api/ApiUtils';
import Pagination from '../../../components/commons/Pagination';
import CardFooter from "reactstrap/lib/CardFooter";

class ViewUserSpenditure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          name: '',
          amount: '',
          currency: '',
          date: '',
          spenditure_id: '',
          my_budget: [],
          curentMonthSpenditure: '',
          chart_data: ''
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        
      }

 async componentDidMount() {
     const response = await getBudgets();

    this.setState({
      my_budget: response.data.data.budget
    });
   
  }
  handleSingleBudgetView = async (e,budget) => {
    e.preventDefault()
    console.log(budget.value);
    this.props.history.push('/admin/view-single-budget');
  }
   render() {
    var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">My Budget</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Index</th>
                      <th scope="col">Year</th>
                      <th scope="col">Month</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Amount Spent</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.my_budget.map((budget, index) => (
                        <tr>
                        <td>{index+1}</td>
                        <td>{budget.year}</td>
                        <td>{months[budget.month-1]}</td>
                        <td>{budget.currency}</td>
                        <td>{budget.amount}</td>
                        <td>{budget.actual_amount_spent}</td>
                        <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              value={budget}
                              onClick={() => this.props.history.push({
                                pathname:'/admin/view-single-budget',
                                state: budget
                              })}
                            >
                              View
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                             Edit
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                        </tr>
                    ))}
                  </tbody>
                </Table>
                <CardFooter>
                  <Pagination />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}


export default ViewUserSpenditure;