import React from "react";
import jwt from 'jwt-decode';
import FilterResults from 'react-filter-search';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header.js";
import {addUserSpenditure, getSpenditureItems} from '../../api/ApiUtils';
import ErrorDisplay from '../../components/commons/ErrorDisplay';

class AddUserSpenditure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          name: '',
          amount: '',
          currency: '',
          date: '',
          spenditure_id: '',
          spenditures: [],
          // data: [],
          value: ''
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        console.log("submmiting user spenditure")
        var accesstoken = localStorage["accesstoken"];
        var decoded = jwt(accesstoken);
        
        // console.log(`this is user data ${JSON.stringify(this.state.user)}`)
        this.setState({
          errormessage: '',
          show: false,
          formSubmitting: true
         });
      
        let data ={
            user_id: decoded.userId,
            spenditure_id: this.state.spenditure_id,
            amount: this.state.amount,
            currency: this.state.currency,
            date: this.state.date
        }
      
        const response = await addUserSpenditure(data);
      
        if(response.data) {
          this.setState({
            isRegistered: true,
            show:true,
            formSubmitting: false,
            name:''
          });
          // this.props.history.push('/dashboard');
        //   window.location.reload()
        }
      
        if (response.error) {
        //   console.log(response.error);
          // console.log(response.error.response);
          if (response.error.response != null) {
            this.setState({
              errormessage: response.error.response.data.data.message,
              show: true,
              formSubmitting: false
            });
          } else {
            this.setState({
              errormessage: response.error.response,
              show: true,
              formSubmitting: false
            });
          }
        }
      }
//handle input change
handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;
    console.log(target.value)
    this.setState({
        [inputName]: inputValue
    });
  }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ name: value });
  };

 async componentDidMount() {
   
      const response = await getSpenditureItems();
      if(response.data) {
         var spenditures =  response.data.data.spenditure;
        this.setState({
          spenditures
        });
      }
// console.log(this.state.spenditures)
   
  }
  render() {
  
    const {value, name} = this.state
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Spenditure Item</h3>
                </CardHeader>
                <CardBody>
                  <ErrorDisplay 
                    show={this.state.show}
                    errormessage={this.state.errormessage}
                    formSubmitting={this.state.formSubmitting} 
                    isRegistered={this.state.isRegistered}/>
                  <Row className=" icon-examples">
                  <Form role="form" onSubmit={this.handleSubmit}>
                      
                {/* spenditure item */}

                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-basket" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" value={name} onChange={this.handleChange} />
                          <FilterResults
                            value={name}
                            data={this.state.spenditures}
                            renderResults={results => (
                              <div>
                                <Input type="select" name="spenditure_id" required id="exampleSelect" onChange={this.handleInputChange}>
                                  <option>Expense Item</option>
                                  {results.map(spenditure => (

                                    <option value={spenditure.id} >{spenditure.name}</option>

                                  ))}
                                </Input>
                              </div>
                            )}
                          />
                        </InputGroup>
                      </FormGroup>

                {/* Amount */}
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-money-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Amount" name="amount" required value={this.state.amount} type="number" autoComplete="new_name" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                {/* Currency */}
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-basket" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Currency" name="currency" required value={this.state.currency} type="text" autoComplete="new_name" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                {/* Date */}
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Date" name="date" required value={this.state.date} type="date" autoComplete="new_name" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Add Spenditure Item
                  </Button>
                </div>
              </Form> 
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddUserSpenditure;
