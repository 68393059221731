import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { initialState, rootReducer } from '../reducers';
import { callApiInterceptor } from '../middleware';
// import { composeWithDevTools } from 'remote-redux-devtools';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';

export const configureStore = () => {

  // informing the store available middlewares
  const middleware = [
    thunkMiddleware,
    callApiInterceptor,
  ];

  /*
   * log our store in development mode only .
   * the above is because of security and performance seek
   * you can remove the below code it won't affect anything in redux
   * remember to uninstall redux-logger if its not required
   */

  // eslint-disable-next-line no-undef
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(
    ...middleware,
  );

  const enhancers = [
    middlewareEnhancer,
  ];

  const composedEnhancers = composeWithDevTools(
    ...enhancers,
  );

  return createStore(rootReducer, initialState, composedEnhancers);
};
