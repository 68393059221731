import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  FormGroup,
  Form,
  Input,
  Col,
  Row
} from "reactstrap";
// core components
import Header from "../../../components/Headers/Header.js";
import {addUserBudget, getUserExpenseCategories} from '../../../api/ApiUtils';
import ErrorDisplay from '../../../components/commons/ErrorDisplay';
import ExpenseItemForm from './ExpenseItemForm';

class AddBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isRegistered: false,
          error: '',
          errorMessage: '',
          formSubmitting: false,
          year: '',
          month: '',
          currency: '',
          budget_items: [],
          temp_budget_item: '',
          temp_budget_amount: 0,
          spenditure_categories: []
        };
      }

       handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("submmiting login")
        // console.log(`this is user data ${JSON.stringify(this.state.user)}`)
        this.setState({
          errormessage: '',
          show: false,
          formSubmitting: true
         });
      
        let data ={
          year: this.state.year,
          month: this.state.month,
          currency: this.state.currency,
          budget_items: this.state.budget_items
        }

        console.log(data)
      
        const response = await addUserBudget(data);
      
        if(response.data) {
          this.setState({
            isRegistered: true,
            show:true,
            formSubmitting: false,
            name:''
          });
          // this.props.history.push('/dashboard');
        //   window.location.reload()
        }
      
        if (response.error) {
          console.log(response.error);
          // console.log(response.error.response);
          if (response.error.response != null) {
            this.setState({
              errormessage: response.error.response.data.data.message,
              show: true,
              formSubmitting: false
            });
          } else {
            this.setState({
              errormessage: response.error.response,
              show: true,
              formSubmitting: false
            });
          }
        }
      }
//handle input change
handleInputChange = (event) => {
  const target = event.target;
  const inputName = target.name;
  const inputValue = target.value;
  if(inputName === "month") {
    this.setState({
      [inputName]: inputValue.split('-')[1]
    })
  }else {

    // console.log(target.value)
    this.setState({
        [inputName]: inputValue
    });
  }
  }
  handleBudgetItemArray = (e) => {
    e.preventDefault();
   const data = {
     item: this.state.temp_budget_item,
     amount: this.state.temp_budget_amount
   }
    this.setState({
      budget_item: this.state.budget_items.push(data)
    })
  }

  async componentDidMount() {
   
    const response = await getUserExpenseCategories();
    if(response.data) {
       var spenditure_categories =  response.data.data.spenditure_categories;
      this.setState({
        spenditure_categories
      });
    }
// console.log(this.state.spenditures)
 
}


  render() {
   
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Budget</h3>
                </CardHeader>
                <CardBody>
                  <ErrorDisplay 
                    show={this.state.show}
                    errormessage={this.state.errormessage}
                    formSubmitting={this.state.formSubmitting} 
                    isRegistered={this.state.isRegistered}/>
                  <Row className=" icon-examples">
                  <Form role="form" className="ml-4" onSubmit={this.handleSubmit}>
                  <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-year"
                            >
                              Year
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-year"
                              name="year"
                              placeholder="2020"
                              type="year"
                              required
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-month"
                            >
                              Month
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-month"
                              name="month"
                              placeholder="2017-06"
                              type="month"
                              required
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-currency"
                            >
                              Currency
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-currency"
                              name="currency"
                              placeholder="KES"
                              type="text"
                              required
                              onChange={this.handleInputChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                        <Button className="my-4" color="primary" type="submit">
                    Submit
                  </Button>
                        </Col>
                      </Row>
        
              </Form> 
              <div className="h3">
                        <p className="h3"> Budget Items:  <i className=" ni ni-fat-add" /> </p>
                      
                        <ExpenseItemForm 
                          budget_items={this.state.budget_items}
                          spenditure_categories= {this.state.spenditure_categories}
                          handleInputChange={this.handleInputChange} 
                          handleBudgetItemArray={this.handleBudgetItemArray}/>

                      </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddBudget;
