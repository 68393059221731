import React from "react";
// import './RenderBudgetItems.css';
import { Table } from "reactstrap";


const RenderBudgetItems = ({ budget_items }) => {
   const render_rows =[]
   for(let i =0; i< budget_items.length; i++) {
       render_rows.push(
           <tr key={i}>
               <th scope="row">{i+1}</th>
               <td>{budget_items[i]?.item}</td>
               <td>{budget_items[i]?.amount}</td>
           </tr>
       )
   }
      
   
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Item</th>
          <th>Amount</th>
      
        </tr>
      </thead>
      <tbody>
      {render_rows}
      </tbody>
    </Table>
  )
};

export default RenderBudgetItems;