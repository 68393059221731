import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import {userRegister} from '../../api/ApiUtils';
import ErrorDisplay from '../../components/commons/ErrorDisplay';


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistered: false,
      error: '',
      errorMessage: '',
      formSubmitting: false,
      name: '',
      email: '',
      password: '',
      msisdn: '',
      redirect: props.location,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      errormessage: '',
      show: false,
      formSubmitting: true
     });

   const data = {
     name: this.state.name,
     password: this.state.password,
     email: this.state.email,
     msisdn: this.state.msisdn
   }
   const response = await userRegister(data);
   console.log(response)

   if(response.data) {
    this.setState({
     formSubmitting: false,
      error: ''
    });
    this.props.history.push('/dashboard');
  }
  if (response.error) {
    console.log(response.error);
    // console.log(response.error.response);
    if (response.error.response != null) {
      this.setState({
        errormessage: response.error.response.data.data.message,
        show: true,
        formSubmitting: false
      });
    } else {
      this.setState({
        errormessage: response.error.response,
        show: true,
        formSubmitting: false
      });
    }
  }
}

     //handle input change
 handleInputChange = (event) => {
  const target = event.target;
  const inputName = target.name;
  const inputValue = target.value;
  
  this.setState({
      [inputName]: inputValue
  });
}
  

  render() {
    let errorMessage = this.state.errorMessage;
  let arr = [];
  if (typeof(errorMessage) == Array) {
    Object.values(errorMessage).forEach((value) => (
      arr.push(value)
      ));
} else {
    arr.push(errorMessage);
}
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-4">
                <small>Sign up with</small>
              </div>
              <div className="text-center">
                <Button
                  className="btn-neutral btn-icon mr-4"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../../assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("../../assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign up with credentials</small>
              </div>

              <ErrorDisplay 
                show={this.state.show}
                errormessage={this.state.errormessage}
                formSubmitting={this.state.formSubmitting} 
                isRegistered={this.state.isRegistered}/>

              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" name="name" type="text"  required onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Phone Number" name="msisdn" type="text" required onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" name="email" type="email" required onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon> 
                    <Input placeholder="Password" name="password" type="password" required onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>

                <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Register;
